@import '../../styles/color.scss';
@import '../../styles/spacing.scss';

.aside {
    width: $sp-aside-width;
    height: calc(100vh - #{$sp-header-height} - #{$sp-footer-height});
    overflow-y: auto;
    background: $cp-secondary-bg-color;
    padding: 30px;
}

.searchSimilarContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;

    .aside_title {
        width: 100%;
        padding-bottom: 5px;
        border-bottom: 3px solid #{$cp-third-color};
        display: flex;
        justify-content: center;
    }

    .imgContent {
        width: 230px;
        height: 230px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $sp-main-radius;
        background: $cp-box-bg-color;
        box-shadow: $cp-box-shadow;
        margin-top: 5px;

        img {
            height: 100%;
        }
    }
}

.aside_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    color: $cp-title-color;
    margin-bottom: 10px;

    .titleContent {
        display: flex;
        align-items: center;
    }

    .aside_titleIcon {
        color: $cp-secondary-color;
        margin-right: 5px;
    }
}

.list {
    margin-bottom: 20px;

    .listTitleContent {
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        padding: 12px 8px;
        border-top: 3px solid #{$cp-third-color};
    }

    .listCollapse {
        margin-top: 10px;

        .productItemsContent {
            display: flex;
            flex-direction: column;
        }
    }

    .input_box {
        width: 100%;
        height: 38px;
        font-size: 18px;
        padding: 0 10px;
        border-radius: $sp-input-radius;
        border: 2px solid #{$cp-secondary-color};
    }

    .seasonSelect {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .seasonBtn {
            width: 50px;
            height: 25px;
            border: none;
            border-radius: $sp-secondary-radius;
            color: $cp-darkbg-text-color;
            background: $cp-btn-color;

            &:hover {
                background: $cp-btn-hover-color;
            }

            &:active {
                box-shadow: $cp-btn-active-shadow;
            }
        }
    }
}

.hr {
    width: 100px;
    height: 3px;
    color: $cp-third-color;
}

.btn {
    width: 230px;
    height: 48px;
    color: #fff;
    font-size: 16px;
    background: $cp-btn-color;
    border: 0px;
    border-radius: $sp-main-radius;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
    box-shadow: $cp-btn-box-shadow;

    &:hover {
        background: $cp-btn-hover-color;
    }

    &:active {
        background: $cp-btn-hover-color;
        box-shadow: $cp-btn-active-shadow;
    }
}

.btn_showDetail {
    margin-bottom: 30px;
}