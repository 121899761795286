@import '../../styles/color.scss';
@import '../../styles/spacing.scss';

.footer {
    width: 100%;
    height: $sp-footer-height;
    background: $cp-HF-bg-color;
    color: $cp-darkbg-text-color;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}