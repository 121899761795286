// primary, secoondary color
$cp-main-color: #434343;
$cp-secondary-color: #FCA777;
$cp-secondary-color-half: #fbceb3;
$cp-third-color: #8B78B7;

//button color
$cp-btn-color: #8B78B7;
$cp-btn-hover-color: #7056AB;
$cp-btn-active-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25) inset;
$cp-btn-border-color: #777;
$cp-btn-text-color: #fff;
$cp-btn-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

// text color
$cp-title-color: #272727;
$cp-text-color: #434343;
$cp-detail-text-color: #646464;
$cp-darkbg-text-color: #fff;

// background color
$cp-main-bg-color: #fff;
$cp-secondary-bg-color: #f5f5f5;
$cp-HF-bg-color: #434343;
$cp-style-bg-color: rgba(252, 167, 119, 0.75);

// form-box color
$cp-form-box-bg-color: #fff;
$cp-form-box-border-color: #FCA777;
$cp-form-box-email-bg-color: #FFE7DA;

// imgContent
$cp-box-bg-color: #e0e0e0;
$cp-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
$cp-box-hover-color: rgba(0, 0, 0, 0.20);
$cp-box-inner-bg-color: #f4f4f4;

// img Hover Content
$cp-box-hover-func-color: rgba(255, 255, 255, 0.74);

// Error Color
$cp-error-bg-color: #FDEDED;
$cp-error-title-color: #DD5C5C;
$cp-error-text-color: #5F2120;

// funcIcon
$cp-func-hover-color: #acacac5d;
$cp-popup-func-hover-color: #cbcbcb33;