@import '../../styles/spacing.scss';
@import '../../styles/color.scss';

.screen {
    min-height: calc(100vh - #{$sp-header-height} - #{$sp-footer-height});
    display: grid;
    grid-template-columns: $sp-aside-width 1fr;
}

.screenContent {
    width: calc(100vw - #{$sp-aside-width});
    padding: 50px 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.uploadTitleContent {

    width: 100%;

    .uploadTitle {
        width: 200px;
        color: $cp-title-color;
        display: flex;
        flex-direction: column;
        align-items: center;

        .uploadTitle_title {
            font-size: 32px;
            z-index: 2;
        }

        .uploadTitle_underLine {
            width: 200px;
            height: 8px;
            background: $cp-secondary-color;
            border-radius: $sp-main-radius;
            margin-top: -10px;
        }
    }

    .uploadInfo {
        display: flex;
        align-items: center;
        margin-top: 20px;

        .uploadInfo_infoIcon {
            color: $cp-third-color;
            font-size: 30px;
        }

        .uploadInfo_text {
            color: $cp-title-color;
            font-size: 20px;
            margin-left: 5px;
        }
    }
}



.uploadFileContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .dropzone {
        width: calc(100% / 2) !important;
        height: 280px;
    }

    .uploadFile_showContent {
        width: calc(100% / 2);
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 35px;
        border: 3px solid $cp-form-box-border-color;
        // background: $cp-form-box-border-color;
        border-radius: $sp-main-radius;
        padding: 0 25px;
        animation: slideDown 0.5s ease;

        .showContent_left {
            width: 75%;
            height: 100%;
            display: flex;
            align-items: center;

            .imgContent {
                width: 100px;
                height: 80%;
                background: $cp-box-bg-color;
                margin-right: 15px;
                border-radius: $sp-main-radius;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .imgTitle {
                width: calc(100% - 120px);
                font-size: 16px;
                font-weight: bold;
                overflow-wrap: break-word;
            }
        }

        .showContent_right {
            width: 25%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: end;

            .fileSize {
                height: 32px;
                padding: 0 10px;
                font-size: 16px;
                background: #f5f6f8;
                color: #838383;
                display: flex;
                align-items: center;
                border-radius: $sp-input-radius;
            }

            .line {
                font-size: 30px;
                margin: 6px 10px 10px 10px;
                color: #838383;
            }

            .iconContent {
                width: 32px;
                height: 32px;
                font-size: 30px;
                background: #f5f6f8;
                color: #838383;
                border-radius: $sp-input-radius;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    color: $cp-error-title-color;
                    background: $cp-error-bg-color;
                }
            }
        }
    }

    .uploadContent_btn {
        width: calc(100% / 2);
        height: 45px;
        color: #fff;
        font-size: 16px;
        background: $cp-btn-color;
        border: 0px;
        border-radius: $sp-main-radius;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 35px;
        box-shadow: $cp-btn-box-shadow;

        &:hover {
            background: $cp-btn-hover-color;
        }

        &:active {
            background: $cp-btn-hover-color;
            box-shadow: $cp-btn-active-shadow;
        }

        .uploadContent_icon {
            margin-left: 5px;
            font-size: 25px;
        }
    }

}

@keyframes slideDown {
    0% {
        max-height: 0;
        opacity: 0;
    }

    100% {
        max-height: 500px;
        opacity: 1;
    }
}