/*
 * This is for global css usage.
 */

@import './styles/color.scss';
@import './styles/spacing.scss';

html {
    height: 100%;
}

*,
*::after,
*::before {
    box-sizing: border-box;
    font-family: 'Noto Sans TC', 'PingFangTC-Regular', 'Microsoft JhengHei',
        'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    outline: none;
}

body,
input,
select,
textarea,
button {
    -webkit-font-smoothing: antialiased;
    font-family: 'Noto Sans TC', 'PingFangTC-Regular', 'Microsoft JhengHei',
        sans-serif;
}

body {
    margin: 0;
    overflow-x: hidden;
    width: 100%;
    background-color: #fff;
    font-size: 18px;
    color: $cp-text-color;
}

// textarea,
// input {
//     /* stylelint-disable-next-line property-no-vendor-prefix */
//     -webkit-appearance: none;
//     border-radius: 0;

//     &::placeholder {
//         color: var(--light-gray);
//     }
// }

input {
    width: 100%;
    border: 0;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black);

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -webkit-appearance: none;
        margin: 0;
    }

    // &:-webkit-autofill {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    // -webkit-box-shadow: 0 0 0 30px white inset;
    // }

    // &[type='number'] {
    //     /* stylelint-disable-next-line property-no-vendor-prefix */
    //     -moz-appearance: textfield;
    // }
}

a {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    border-radius: $sp-secondary-radius !important;
}

button {
    cursor: pointer;
}

// Mui UI form outline 
.MuiOutlinedInput-notchedOutline {
    border: 2px solid $cp-secondary-color-half !important;
}

// Mui UI form outline focus
.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $cp-form-box-border-color !important;
}

// Mui UI formSelect label
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
    color: $cp-title-color !important;
}

// Mui UI Fab -> DeepSearch
.css-mm42gz-MuiButtonBase-root-MuiFab-root{
    background: $cp-btn-color !important;
    color: $cp-darkbg-text-color !important;

    &:hover{
        background: $cp-btn-hover-color !important;
    }
}