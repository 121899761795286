$sp-header-height: 65px;
$sp-footer-height: 50px;
$sp-aside-width: 300px;

// spacing
$sp-main-spcing: 30px;

// radius
$sp-input-radius: 5px;
$sp-main-radius: 10px;
$sp-secondary-radius: 20px;
