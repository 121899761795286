@import '../../styles/color.scss';
@import '../../styles/spacing.scss';

.errorContent {
    width: 100%;
    background: $cp-error-bg-color;
    padding: 20px;
}

.topContent {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .titleContent {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        .errorIcon {
            font-size: 32px;
            color: $cp-error-title-color;
            margin-right: 5px;
        }

        .title {
            font-size: 24px;
            font-weight: bold;
            color: $cp-error-text-color;
        }
    }
}


.text {
    font-size: 20px;
    color: $cp-error-text-color;
    margin-left: 36px;
}