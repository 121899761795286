@import '../../styles/color.scss';
@import '../../styles/spacing.scss';

.header {
    position: sticky;
    top: 0;
    height: $sp-header-height;
    width: 100%;
    background: $cp-HF-bg-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
}

.header_leftSide {
    height: 100%;
    display: flex;
    align-items: center;

    .logoSide {
        height: 65%;

        img {
            height: 100%;
        }
    }

    .logoName {
        margin-left: 10px;
        color: $cp-darkbg-text-color;
    }

    .homeIcon {
        color: $cp-darkbg-text-color;
        font-size: 26px;
    }
}

.header_rightSide {
    display: flex;
    align-items: center;
    color: $cp-darkbg-text-color;

    .bellIcon {
        color: $cp-darkbg-text-color;
        margin-right: 10px;
    }
}