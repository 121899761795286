@import '../../styles/color.scss';
@import '../../styles/spacing.scss';

.select {
    width: 100%;
    height: 40px;
    border: 2px solid $cp-form-box-border-color;
    border-radius: $sp-input-radius;
    font-size: 16px;
    color: $cp-text-color;
    padding: 0 10px;
    cursor: pointer;

    option:checked {
        background-color: rgba(255, 160, 122, 0.5);
        color: $cp-text-color;
    }
}
