@import '../../styles/spacing.scss';
@import '../../styles/color.scss';

.screen {
    display: grid;
    grid-template-columns: $sp-aside-width 1fr;
}

.screenContent {
    padding: 30px 45px;
    height: calc(100vh - #{$sp-header-height} - #{$sp-footer-height});
    overflow-y: auto;
}

.resultTitle {
    color: $cp-title-color;
    display: flex;
    align-items: center;

    .icon {
        color: $cp-secondary-color;
        margin-right: 10px;
    }

    .resultTitle_title {
        font-size: 32px;
    }
}

.resultContent {
    display: grid;
    padding: 0px 20px 0px 20px;
    grid-template-columns: repeat(auto-fit, 250px);
    grid-template-rows: repeat(auto-fit, 250px);
    grid-gap: 35px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.parentContainer {
    position: relative;
    background: $cp-box-bg-color;
    border-radius: $sp-main-radius;
    box-shadow: $cp-box-shadow;
    cursor: pointer;

    &:hover .hoverContent {
        display: block;
    }

    &:hover .itemContent{
        display: none;
    }
}

.imgContent {
    position: relative;
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;

    .imgContent_img {
        height: 90%;
        align-self: center;
    }

    // error image
    .errorImageContent {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .errorIcon {
            color: gray;
            font-size: 64px;
        }

        // .errorImage {
        //     width: 45%;
        // }

        .errorImageText {
            color: gray;
            font-size: 16px;
            font-weight: bold;
            margin-top: 15px;
        }
    }
}

.itemContent {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 230px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $sp-secondary-radius;
    background: $cp-box-hover-func-color;
    margin: 10px;
}

.hoverContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 250px;
    height: 250px;
    padding: 16px;
    background: $cp-box-hover-color;
    border-radius: $sp-main-radius;
    // pointer-events: none;
    display: none;

    .clickContent {
        width: 100%;
        height: 173px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        .clickContent_title {
            padding: 5px;
            font-size: 16px;
            background: $cp-style-bg-color;
            border-radius: $sp-main-radius;
            color: $cp-title-color;
            font-weight: bold;
        }
    }

    .funcContent {
        width: 216px;
        height: 45px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 16px;
        border-radius: $sp-secondary-radius;
        background: $cp-box-hover-func-color;
        padding: 0 5px;

        .funcContent_func {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 5px;

            &:hover {
                border-radius: 50px;
                background: $cp-func-hover-color;
            }
        }

        .funcContent_icon {
            font-size: 20px;
            color: $cp-text-color;
        }
    }
}

.resultContent_show {
    grid-template-columns: repeat(auto-fit, 480px);
    grid-template-rows: repeat(auto-fit, 280px);
    padding: 0;
    margin-top: 30px;

    .mainContent {
        width: 480px;
        height: 280px;
        display: flex;
        padding: 22px 22px 22px 0;

        .imgContent {
            width: 280px;
            height: 214px;

            // error image
            .errorImageContent {

                .errorIcon {
                    font-size: 60px;
                    margin-bottom: 15px;
                }

                // .errorImage {
                //     width: 60%;
                //     margin-bottom: 15px;
                // }

                .errorImageText {
                    margin-top: 0px;
                }
            }
        }

        .detailContent {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            background: $cp-box-inner-bg-color;
            border-radius: $sp-main-radius;

            .detailContent_title {
                width: 100%;
                height: 30px;
                font-size: 18px;
                color: $cp-title-color;
                text-align: center;
                margin-top: 25px;
            }

            .ul {
                width: 100%;
                height: calc(100% - 80px);
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
                margin-left: 20px;

                li {
                    font-size: 14px;
                    color: $cp-detail-text-color;
                    width: 50%;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.itemContent_show{
    width: 160px;
    font-size: 14px;
    margin-left: 5px;
    margin-right: 10px;
    margin-bottom: 20px;
}

.hoverContent_show {
    width: 480px;
    height: 280px;

    .clickContent {
        height: 210px;
        align-items: start;

        .clickContent_title {
            width: 40%;
            margin-left: -15px;
        }
    }

    .funcContent {
        width: 100%;
        padding: 0 25px;
    }
}

.popupContent {
    width: 540px;
    height: 600px;
    border: 5px solid $cp-third-color;
    border-radius: $sp-secondary-radius;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .title {
        color: $cp-title-color;
        font-weight: bold;
        font-size: 20px;
        z-index: 2;
    }

    .underLine {
        width: 160px;
        height: 8px;
        background: $cp-secondary-color;
        border-radius: $sp-main-radius;
        margin-top: -15px;
    }

    .iconContent {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 20px;

        .iconContent_icon {
            cursor: pointer;
            font-size: 28px;
        }
    }

    .imgContent {
        height: 380px;
        margin: 10px;
        display: flex;

        img {
            height: 96%;
        }
    }

    .funcContent {
        width: 400px;
        height: 50px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: $cp-darkbg-text-color;
        background: $cp-third-color;
        border-radius: $sp-secondary-radius;
        margin-bottom: 10px;

        .funcContent_func {
            display: flex;
            cursor: pointer;
            padding: 5px;

            &:hover {
                border-radius: 50px;
                background: $cp-popup-func-hover-color;
            }
        }

        .funcContent_icon {
            color: $cp-darkbg-text-color;
            margin-right: 2px;
        }
    }
}

.popupContent_email {

    .titleContent {
        display: flex;
        margin-bottom: 10px;
        margin-top: -15px;

        .icon {
            color: $cp-secondary-color;
            margin-right: 5px;
        }
    }

    .mainContent {
        width: 100%;
        padding: 0 20px;

        .selectContent {
            display: flex;
            align-items: center;
            margin-bottom: 25px;

            .selectContent_title {
                font-size: 18px;
                color: $cp-title-color;
                margin-right: 20px;
            }

            .value {
                width: 220px;
                height: 38px;
                border: 2px solid $cp-form-box-border-color;
                border-radius: $sp-input-radius;
                font-size: 16px;
                color: $cp-text-color;
                display: flex;
                align-items: center;
                padding: 0 10px;
            }

            .formSelect {
                width: 220px;
                height: 38px;
                border: 2px solid $cp-form-box-border-color;
                border-radius: $sp-input-radius;
                font-size: 16px;
                color: $cp-text-color;
                display: flex;
                align-items: center;
                padding: 0 10px;
                cursor: pointer;
            }
        }

        .selectContent_email {
            flex-direction: column;
            align-items: start;
            margin-bottom: 5px;

            .emailContent {
                width: 100%;
                height: 210px;
                display: flex;
                background: $cp-form-box-email-bg-color;
                justify-content: space-between;
                align-items: center;
                border: 2px solid $cp-form-box-border-color;
                border-radius: $sp-main-radius;
                margin-top: 10px;
                padding-left: 20px;

                .emailContent_detail {
                    width: 70%;
                    height: 80%;
                    font-size: 14px;
                    margin: 24px 0;
                    border-radius: $sp-input-radius;
                    border: none;
                    border: 2px solid $cp-form-box-border-color;
                    padding: 10px 5px;
                }

                .imgContent {
                    width: 110px;
                    height: 90%;
                    margin-top: 10px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
    }


    .button {
        width: 50%;
        height: 45px;
        font-size: 16px;
        color: $cp-darkbg-text-color;
        background: $cp-btn-color;
        border: none;
        border-radius: $sp-main-radius;
        cursor: pointer;

        &:hover {
            background: $cp-btn-hover-color;
        }

        &:active {
            box-shadow: $cp-btn-active-shadow;
        }
    }
}

.snackBarClose {
    color: $cp-darkbg-text-color !important;
    background: $cp-btn-color !important;

    &:hover {
        background: $cp-btn-hover-color !important
    }
}

.mode {
    position: absolute;
    top: -15px;
    left: -12px;

    .mode_icon {
        position: absolute;
        font-size: 48px;
        color: #46b5fa;
        transform: rotate(30deg);
    }

    .mode_icon_2D {
        position: absolute;
        font-size: 48px;
        color: #ffb744;
        transform: rotate(30deg);
    }

    .mode_title {
        position: absolute;
        top: 11px;
        left: 10px;
        color: $cp-darkbg-text-color;
    }
}