@import '../../styles/color.scss';
@import '../../styles/spacing.scss';

.formNumContent{
    margin-bottom: 15px;
}
.formNum {
    background: $cp-main-bg-color;
}
.icon{
    color: $cp-secondary-color
}